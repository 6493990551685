import React from "react";
import { graphql } from "gatsby";
import { Button, Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Gift Cards</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
        hero={
          <HeroElement
            header={""}
            tagline={""}
            images={["https://fisherman.gumlet.io/public/eggandbird/eGift_Card_Header_-_Repeat_Design_fpBjhts.jpg"]}
            showMap={false}
            ctas={[]}
            ctaInverted={true}
            ctaColor={"white"}
            containerColor={"primary"}
            textColor={"white"}
            showMultiple={true}
            overlay={true}
            fullWidth={true}
            parallax={true}
            containerAs={"none"}
            height={500}
            gutter={false}
          />
        }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
                <h1>Send an eGift</h1>
                <p>From a delicious meal to a catering feast, treat someone to the gift of Egg N Bird!</p>
                <Button size="big" color="yellow" as="a" href="https://eggnbird.myguestaccount.com/guest/egift" target="_blank" style={{padding:'1em', width:'250px', margin:"1em auto", background:"#ff9e18"}}>
                  BUY EGIFT
                </Button>
                &nbsp;&nbsp;
                <Button size="big" color="yellow" as="a" href="https://eggnbird.myguestaccount.com/guest/nologin/account-balance" target="_blank" style={{padding:'1em', width:'250px', margin:"1em auto", background:"#ff9e18"}}>
                  CHECK BALANCE
                </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
